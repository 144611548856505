/* Form Component.
 *
 * onSubmit can be a string indicating the name of the endpoint
 * to post to on our server, or a custom function that returns
 * a promise
 */

import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import useAppState from "../providers/AppProvider";
import API from "../utilities/API";

const FormContext = createContext({});

FormProvider.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  additionalData: PropTypes.object,
  showSuccess: PropTypes.bool,
};

FormProvider.defaultProps = {
  children: PropTypes.node,
  onSubmit: "contact_us",
  additionalData: {},
  showSuccess: true,
};

export default function FormProvider({
  children,
  onSubmit,
  additionalData,
  showSuccess,
}) {
  const methods = useForm();
  // const { isHotel } = useAppState();
  const { handleSubmit } = methods;

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const renderFields = () => !(success && showSuccess) && children;

  const renderThankYou = () =>
    success &&
    showSuccess && (
      <div className="form__thank_you">
        <p>Thanks for your submission.</p>
      </div>
    );

  const renderError = () =>
    error &&
    !success && (
      <div>
        <p>{error}</p>
      </div>
    );

  const submitHandler = handleSubmit((formData) => {
    let api = new API({ base: API_PATH });
    let submit = api.post.bind(api, onSubmit);

    if (typeof onSubmit === "function") {
      submit = onSubmit;
    }
    console.log("formdata", formData);
    console.log("additionalData", additionalData);
    const cumulativeData = {
      ...formData,
      ...additionalData,
    };
    const finalData = {
      ...cumulativeData,
    };

    submit(finalData)
      .then((resp) => {
        if (resp.error) {
          return setError(error.message || "An error occurred.");
        }
        setSuccess(true);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message || "An error occurred.");
      });
  });

  return (
    <FormContext.Provider value={methods}>
      <form className="form" onSubmit={submitHandler}>
        {renderFields()}
        {renderThankYou()}
        {renderError()}
      </form>
    </FormContext.Provider>
  );
}

export function useFormContext() {
  return useContext(FormContext);
}
