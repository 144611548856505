import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/Footer.scss";
import { useAppState } from "../providers";
import Link from "../widgets/Link";
import { ByPaliSocietyLogo } from "../widgets/SVGs/byPaliSociety";
import { InstagramIcon } from "../widgets/SVGs/InstagramIcon";
import NewsletterForm from "./forms/NewsletterForm";
import ExtendedFooter from "./ExtendedFooter";
const cl = _classes(styles);

function Footer() {
  const { byTemplate } = useAppState();
  const footer = byTemplate("footer")[0];

  const renderLeftSide = () => {
    const returnLink = (field, fallbackLink) => {
      return field !== null && field !== "" ? field : fallbackLink;
    };
    return (
      <div className={cl("left-side-content")}>
        <Link
          path={returnLink(footer?.h2, "https://www.palisociety.com/")}
          target="_blank"
        >
          <ByPaliSocietyLogo />
        </Link>
        <div className={cl("instagram-wrapper")}>
          <Link
            path={"https://www.instagram.com/palisociety"}
            className={cl("instagram")}
            target="_blank"
          >
            <InstagramIcon />
          </Link>
        </div>
      </div>
    );
  };

  const renderCenterContent = () => {
    return (
      <div className={cl("center-content")}>
        <div className={cl("links")}>
          {footer?.fieldgroup1 &&
            footer.fieldgroup1?.length &&
            footer.fieldgroup1?.map((item) => {
              return <Link key={item.id} path={item.h4} title={item.h3} />;
            })}
        </div>
      </div>
    );
  };

  const renderRightSide = () => {
    return (
      <div className={cl("right-side-content")}>
        <div className={cl("newsletter")}>
          <NewsletterForm />
        </div>
      </div>
    );
  };

  return (
    <footer className={cl("_")}>
      <ExtendedFooter />
      <div className={cl("container")}>
        {renderLeftSide()}
        {renderCenterContent()}
        {renderRightSide()}
      </div>
    </footer>
  );
}

export default Footer;
