// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._link__link___MW51J{color:#1d5c39;text-transform:uppercase;font-size:18px;font-weight:500;padding-bottom:.5rem;border-bottom:solid 2px #1d5c39;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-bottom:40px}", "",{"version":3,"sources":["webpack://./src/styles/widgets/_link.scss","webpack://./src/styles/global/_variables.scss"],"names":[],"mappings":"AAEA,qBACE,aCFU,CDGV,wBAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CACA,+BAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,kBAAA","sourcesContent":["@import \"../global/parts\";\n\n.link {\n  color: $darkGreen;\n  text-transform: uppercase;\n  font-size: 18px;\n  font-weight: 500;\n  padding-bottom: 0.5rem;\n  border-bottom: solid 2px $darkGreen;\n  width: fit-content;\n  margin-bottom: 40px;\n}\n","// COLORS\n$darkGreen: #1d5c39;\n$lightGreen: #dbe4de;\n$buttonLightGreen: #c7d2c8;\n$white: #fff;\n$black: #1c1c1c;\n$lightYellow: #fffced;\n$yellow: #f7e090;\n$darkYellow: #f2d97f;\n$conchGreen: #c4d3c8;\n\n$errorRed: rgb(214, 20, 20);\n$focusBlue: #4d90fe;\n//HEADER\n$headerHeight: 115px;\n$headerPadding: calc($headerHeight + 80px);\n\n// BREAKPOINTS\n$xsmall: 400px;\n$small: 575px;\n$mobile: 768px;\n$tablet: 1000px;\n$medium: 1200px;\n$large: 1400px;\n$xlarge: 1600px;\n\n// MISC\n$containerWidth: 1360px;\n$defaultPadding: 4%;\n$transitionSpeed: 0.2s;\n$defaultTransition: 0.2s ease-out;\n\n// ANIMATION\n$speed1: cubic-bezier(0.4, 1.13, 0.7, 0.95);\n// TYPOGRAPHY\n\n// FONT SIZES\n$rootFontSize: 16px;\n\n$h1-size: 3.75rem; // 60px\n$h1-size-mobile: 2rem;\n\n$h2-size: 2.6rem;\n$h2-size-mobile: 1.8rem;\n\n$h3-size: 2rem;\n$h3-size-mobile: 1.6rem;\n\n$h4-size: 1.7rem;\n$h4-size-mobile: 1.4rem;\n\n$h5-size: 1.5rem;\n$h5-size-mobile: 1.3rem;\n\n$h6-size: 1.3rem;\n$h6-size-mobile: 1.2rem;\n\n$p-size: 0.9375rem;\n$p-size-mobile: 0.9375rem;\n\n$a-size: 0.9375rem;\n$a-size-mobile: 0.9375rem;\n\n$span-size: 0.9375rem;\n$span-size-mobile: 0.9375rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "_link__link___MW51J"
};
export default ___CSS_LOADER_EXPORT___;
