import Link from "../../widgets/Link";
import styles from "../../../styles/partials/header-components/Nav.scss";
import { _classes, useCurrentBasePageContent } from "../../utilities/helpers";
import { caretArrow } from "../../widgets/SVGs/caretArrow";
import { useState } from "react";
import { motion } from "../../utilities/FramerMotion";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const cl = _classes(styles);

const slideVerticalAnimation = {
  open: {
    rotateX: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.3, mass: 0.8, type: "linear" },
    display: "block",
  },
  close: {
    rotateX: -15,
    y: -10,
    opacity: 0,
    transition: { duration: 0.3 },
    transitionEnd: { display: "none" },
  },
};

const SubNav = ({ subSelections, isSubMenuOpen, toggleMenu }) => (
  <motion.ul
    initial="close"
    animate={isSubMenuOpen ? "open" : "close"}
    variants={slideVerticalAnimation}
    className={cl("subnav-wrapper")}
  >
    {subSelections.map((item) => (
      <li key={item?.id || item?.path} className={cl("subnav")}>
        <Link
          path={item.checkbox1 ? null : item.path}
          onClick={(e) =>
            item?.onClick ? item.onClick(e, toggleMenu) : toggleMenu()
          }
        >
          {item.linktitle}
        </Link>
      </li>
    ))}
  </motion.ul>
);

const NavItem = ({
  item,
  isSubMenuOpen,
  toggleSubMenu,
  propertyPage,
  toggleMenu,
  history,
}) => {
  const templateIsGuestRooms = item.mainNav.template === "guest_rooms";
  const templateIsEatDrink = item.mainNav.template === "eat_and_drink";
  const isOffersOrEatAndDrink = ["offers", "eat_and_drink"].includes(
    item.mainNav.template
  );

  const itemHasSubNav =
    item.subNav.length > 0 && item.mainNav.template !== "offers"; // If nav item has children and is not children of offers
  const thePath = templateIsGuestRooms
    ? null //if its guest rooms, return null
    : isOffersOrEatAndDrink
    ? item.mainNav.path //if its offers or eat and drink, return the path
    : itemHasSubNav
    ? null //if there is a subnav, don't pass the path as we don't want to navigate to the page but instead open the subnav
    : item.mainNav.path || "/"; //Last, return the path if there is one, otherwise return the root path

  return (
    <li key={item.mainNav.id} className={itemHasSubNav ? cl("has-subnav") : ""}>
      <div className={cl("wrapper")}>
        <Link
          className={cl("nav-item")}
          path={thePath}
          onClick={() => {
            if (templateIsEatDrink || templateIsGuestRooms) {
              history.push({
                hash: "property-rooms",
                pathname: propertyPage.path,
              });
              toggleMenu();
            } else if (itemHasSubNav) {
              toggleSubMenu(item.mainNav.id);
            } else {
              toggleMenu();
            }
          }}
        >
          {item.mainNav.title}
          {itemHasSubNav &&
            !["offers", "eat_and_drink", "guest_rooms"].includes(
              item.mainNav.template
            ) && (
              <div
                className={cl(["icon", isSubMenuOpen ? "open" : ""])}
                onClick={() => toggleSubMenu(item.mainNav.id)}
              >
                {caretArrow}
              </div>
            )}
        </Link>
      </div>
      {itemHasSubNav && (
        <SubNav
          subSelections={item.subNav}
          isSubMenuOpen={isSubMenuOpen}
          toggleMenu={toggleMenu}
        />
      )}
    </li>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  isSubMenuOpen: PropTypes.bool,
  toggleSubMenu: PropTypes.func,
  propertyPage: PropTypes.object,
  toggleMenu: PropTypes.func,
  history: PropTypes.object,
};

SubNav.propTypes = {
  subSelections: PropTypes.array,
  isSubMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

const Nav = ({ selections, toggleMenu }) => {
  const history = useHistory();
  const { basePageContent: propertyPage } = useCurrentBasePageContent();
  const [subNavOpen, setSubNavOpen] = useState("");

  const toggleSubMenu = (id) => setSubNavOpen(subNavOpen === id ? "" : id);

  return (
    <nav className={cl("root")}>
      <ul>
        {selections &&
          selections.map((item) => (
            <NavItem
              key={item.mainNav.id}
              item={item}
              isSubMenuOpen={subNavOpen === item.mainNav.id}
              toggleSubMenu={toggleSubMenu}
              propertyPage={propertyPage}
              toggleMenu={toggleMenu}
              history={history}
            />
          ))}
      </ul>
    </nav>
  );
};

Nav.propTypes = {
  selections: PropTypes.array,
  toggleMenu: PropTypes.func,
};

export default Nav;
