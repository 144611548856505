import * as typedefs from "../../../typedefs";
import { createContext, useContext, useState, useEffect } from "react";
import dayjs from "dayjs";
import { PagesContent } from "../utilities/helpers";

/** @type {typedefs.AppContext}*/
const AppContext = createContext({});

AppProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export function AppProvider({ children, content, history, location }) {
  const pagesContent = new PagesContent(content).reset;
  window.pagesContent = pagesContent;
  const homeSectionsId = pagesContent.where("title").is("Homepage Sections")
    .data.id;
  const homepageSections = pagesContent
    .where("parentid")
    .is(homeSectionsId).data;
  const [userScrolling, setUserScrolling] = useState(false);
  const [accessible, setAccessible] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState(true);
  const homePagePaths = [
    "/",
    "/terms-of-use",
    "/contact-us",
    "/press",
    "accessibility",
  ];
  const [scrollLocation, setScrollLocation] = useState(
    window.location.pathname
  );
  const [heroIsVisible, setHeroIsVisible] = useState(true);

  const _sortEvents = (a, b) => dayjs(a.start_date) - dayjs(b.start_date);

  const byId = (id) => content[id] || {};

  const byPath = (path) => {
    path = path?.toLowerCase();
    path =
      path?.slice(-1) === "/" && path?.length > 1 ? path?.slice(0, -1) : path;

    const id = Object.keys(content).find((key) => content[key].path === path);
    return content[id] || {};
  };

  const byTemplate = (template) =>
    Object.keys(content)
      .filter((key) => content[key].template === template)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenById = (id) =>
    Object.keys(content)
      .filter((key) => content[key].parentid === id)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenByPath = (path) => childrenById(byPath(path).id);

  const parentById = (id) => byId(byId(id).parentid);

  const parentByPath = (path) => byId(byPath(path).parentid);

  const allEvents = () => {
    const events = byTemplate("calendar_detail");

    return events
      .reduce((sum, event) => {
        const eventType =
          event?.calendartype && JSON.parse(event.calendartype).type;

        if (eventType === "recurring" && event.parentid === event.calendarid) {
          const children = childrenById(event.id).sort(_sortEvents);

          const child =
            children.find((e) => datejs(e.start_date) >= datejs()) || event;

          if (datejs(event.start_date) >= datejs()) {
            //if original event is still in future, push OG event
            sum.push(event);
          } else {
            //otherwise push most next upcoming child
            sum.push({
              ...event,
              id: child.id,
              parentid: child.parentid,
              start_date: child.start_date,
              end_date: child.end_date,
            });
          }
        }

        if (eventType !== "recurring") {
          sum.push(event);
        }

        return sum;
      }, [])
      .sort(_sortEvents);
  };

  const activeEvents = () => {
    return allEvents()
      .filter((event) => {
        const today = new Date();
        const calendarType =
          event?.calendartype && JSON.parse(event.calendartype);
        const isActive = dayjs(event.end_date).unix() >= dayjs(today).unix();

        return isActive || (calendarType?.type === "consecutive" && isActive);
      })
      .sort(_sortEvents);
  };
  const checkIfHotel = () => {
    const locationsPage = byTemplate("locations")[0];
    const pathnameIsProperty = pathname.startsWith(locationsPage.path);
    return pathnameIsProperty;
  };
  const isHotel = checkIfHotel(); //Just made this so it wouldn't break if they changed the slug of the locations page
  const toggleAccessible = (e) => {
    e.preventDefault();
    setAccessible(!accessible);
    document.querySelector("html").classList.toggle("accessible");
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const [bookNowModal, setBookNowModal] = useState(false);

  return (
    <AppContext.Provider
      value={{
        // can use this to simplify the use of the finding content functions
        pagesContent,
        content,
        history,
        location,
        byId,
        byPath,
        byTemplate,
        childrenById,
        childrenByPath,
        parentById,
        parentByPath,
        allEvents,
        activeEvents,
        accessible,
        toggleAccessible,
        animating,
        setAnimating,
        menuOpen,
        setMenuOpen,
        bookNowModal,
        setBookNowModal,
        pathname,
        setPathname,
        homePagePaths,
        activeTab,
        setActiveTab,
        isHotel,
        scrollLocation,
        setScrollLocation,
        heroIsVisible,
        setHeroIsVisible,
        homeSectionsId,
        homepageSections,
        userScrolling,
        setUserScrolling,
        loading,
        setLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
/**
 *
 * @returns {typedefs.AppContextData} AppContextData
 *
 **/
export default function useAppState() {
  return useContext(AppContext);
}
