import { _classes } from "../utilities/helpers";
import styles from "../../styles/components/Hamburger.scss";
import useAppState from "../providers/AppProvider";
const cl = _classes(styles);

Hamburger.propTypes = {
  className: PropTypes.string,
};
function Hamburger({ className = "" }) {
  const { menuOpen, setMenuOpen } = useAppState();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <button
      aria-label="Open the menu"
      className={`${cl("_")} ${className}`}
      onClick={() => toggleMenu()}
      onKeyDown={(e) => e.key === "Enter" || (e.key === " " && toggleMenu())}
      tabIndex="0"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
}

export default Hamburger;
